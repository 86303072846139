var sw = window.innerWidth;
var sh = window.innerHeight;
var header = document.querySelector("header");
var sticky = 0;

// Font Resizer ------------------- Start
function fontResizer() {
  var perc = parseInt(sw) / 120;
  document.body.style.fontSize = perc + "px";
}

// Landscape Mode off ---------------- Start
const mql = window.matchMedia("(orientation:landscape)");
mql.addEventListener("change", (event) => {
  if (event.matches) {
    if (innerWidth < 930) {
      // console.log("Now in landscape orientation");
      document.getElementById("portrait-warning").style.display = "flex";
    }
  } else {
    // console.log("Now in portrait orientation");
    document.getElementById("portrait-warning").style.display = "none";
  }
});

// Loader ------------------- Start
function pageLoader() {
  setTimeout(function () {
    if (document.querySelector(".loader-first")) {
      document.querySelector(".loader-first").style.display = "none";
      document.querySelector("html").classList.remove("loadjs");
    }
  }, 700);
}

// Sticky Header ---------------- Start
(function () {
  if (document.querySelector(".header")) {
    // Sticky Header Class Add and Remove ---------------- Start
    function stickyHeader() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
    // Sticky Header ---------------- End

    // Window On Scroll-----------------Start
    window.addEventListener("scroll", (event) => {
      stickyHeader();
    });
  }
})();

// Window on Load ----------------- Start
window.onload = function () {
  pageLoader();
  if (sw > 1199) {
    fontResizer();
  }
};

// Window On Resize ----------------- Start
(function () {
  window.addEventListener("resize", (event) => {
    sw = window.innerWidth;
    sh = window.innerHeight;
    if (sw > 1025) {
      if (sw < 1400 && sw > 1300 && sh > 900) {
      } else {
        fontResizer();
      }
    }
  });
})();

// SVG Convert Code
window.addEventListener("load", function () {
  (function () {
    if (document.querySelector(".svg-convert")) {
      let svgs = document.querySelectorAll("img.svg-convert");
      svgs.forEach((el) => {
        const imgID = el.getAttribute("id");
        const imgClass = el.getAttribute("class");
        const imgURL = el.getAttribute("src");

        fetch(imgURL)
          .then((response) => response.text())
          .then((data) => {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(data, "text/html");
            let svg = xmlDoc.querySelector("svg");
            if (typeof imgID !== "undefined") {
              svg.setAttribute("id", imgID);
            }

            if (typeof imgClass !== "undefined") {
              svg.setAttribute("class", imgClass + " svg-converted");
            }

            svg.removeAttribute("xmlns:a");

            el.parentNode.replaceChild(svg, el);
          });
      });
    }
  })();
});

// Navigation slide Active on a mobile ---------------- Start
(function () {
  if (document.querySelector("#toggle")) {
    let toggleMenu = document.querySelector("#toggle");
    let htmlEl = document.querySelector("html");

    toggleMenu.addEventListener("click", (event) => {
      // console.log('this', event)
      let _this = event.target;
      _this.classList.toggle("active");
      document.querySelector(".navigation").classList.toggle("open");
      htmlEl.classList.toggle("overflow-hidden");
    });
  }
})();

// -------------------- above code will be common for all websites --------------------------

// Home page Sliders------Start

if (document.querySelector(".blogSlider")) {
  var blogSlider = new Swiper(".blogSlider .swiper", {
    slidesPerView: "auto",
    loop: false,
    speed: 800,
    scrollbar: {
      el: ".blogSlider .swiper-scrollbar",
      hide: false,
      draggable: true,
    },
  });
}

if (document.querySelector(".testimonialSlider")) {
  var testimonialSlider = new Swiper(".testimonialSlider .swiper", {
    slidesPerView: "auto",
    loop: false,
    speed: 5000,
    freeMode: true,
    loop: true,
    autoplay: {
      delay: 0,
    },
  });
}

if (document.querySelector(".workCardsWrapper")) {
  if (sw < 1199 || (sw < 1400 && sw > 1300 && sh > 900)) {
    var workCardsSlider = new Swiper(".workCardsWrapper .swiper", {
      slidesPerView: 1,
      loop: true,
      speed: 800,
      spaceBetween: 15,
    });
  }
}

if (document.querySelector(".serviceSection__mob")) {
  if (sw < 1199 || (sw < 1400 && sw > 1300 && sh > 900)) {
    var serviceSlider__mob = new Swiper(".serviceSlider__mob .swiper", {
      slidesPerView: 1,
      loop: true,
      speed: 800,
      spaceBetween: 15,
    //  autoHeight: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: true,
      },
      breakpoints: {
        576: {
          slidesPerView: 2,
        },
        991: {
          slidesPerView: 3,
        },
      },
    });
  }
}
//Video Section---------------Start

setTimeout(function () {
  let srcVideo =
    "https://player.vimeo.com/video/872884001?h=d7bec5dce4&background=1";
  $("#videoBanner").html(
    '<iframe src="' +
      srcVideo +
      '" allow="autoplay" allowfullscreen frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="Digital Gravity X" style="width: 100% ;height: 100%;"></iframe>'
  );
}, 5000);

//Video Section----------------End

// Video Modal-------------------Start

if (document.querySelector(".video-modal")) {
  function videoId(button) {
    var $videoUrl = button.attr("data-video");
    if ($videoUrl !== undefined) {
      var $videoUrl = $videoUrl.toString();
      var srcVideo;

      if ($videoUrl.indexOf("youtube") !== -1) {
        var et = $videoUrl.lastIndexOf("&");
        if (et !== -1) {
          $videoUrl = $videoUrl.substring(0, et);
        }
        var embed = $videoUrl.indexOf("embed");
        if (embed !== -1) {
          $videoUrl =
            "https://www.youtube.com/watch?v=" +
            $videoUrl.substring(embed + 6, embed + 17);
        }

        srcVideo =
          "https://www.youtube.com/embed/" +
          $videoUrl.substring($videoUrl.length - 11, $videoUrl.length) +
          "?autoplay=1&mute=0&loop=1&playlist=" +
          $videoUrl.substring($videoUrl.length - 11, $videoUrl.length) +
          "";
      } else if ($videoUrl.indexOf("youtu") !== -1) {
        var et = $videoUrl.lastIndexOf("&");
        if (et !== -1) {
          $videoUrl = $videoUrl.substring(0, et);
        }
        var embed = $videoUrl.indexOf("embed");
        if (embed !== -1) {
          $videoUrl =
            "https://youtu.be/" + $videoUrl.substring(embed + 6, embed + 17);
        }

        srcVideo =
          "https://www.youtube.com/embed/" +
          $videoUrl.substring($videoUrl.length - 11, $videoUrl.length) +
          "?autoplay=1&mute=1&loop=1&playlist=" +
          $videoUrl.substring($videoUrl.length - 11, $videoUrl.length) +
          "";
      } else if ($videoUrl.indexOf("vimeo") !== -1) {
        srcVideo =
          "https://player.vimeo.com/video/" +
          $videoUrl
            .substring($videoUrl.indexOf(".com") + 5, $videoUrl.length)
            .replace("/", "") +
          "?autoplay=1";
      } else if ($videoUrl.indexOf("mp4") !== -1) {
        return (
          '<video loop playsinline autoplay><source src="' +
          $videoUrl +
          '" type="video/mp4"></video>'
        );
      } else if ($videoUrl.indexOf("m4v") !== -1) {
        return (
          '<video loop playsinline autoplay><source src="' +
          $videoUrl +
          '" type="video/mp4"></video>'
        );
      } else {
        alert(
          "The video assigned is not from Youtube, Vimeo or MP4, remember to enter the correct complete video link .\n - Youtube: https://www.youtube.com/watch?v=43ngkc2Ejgw\n - Vimeo: https://vimeo.com/111939668\n - MP4 https://server.com/file.mp4"
        );
        return false;
      }
      return (
        '<iframe src="' +
        srcVideo +
        '" allow="autoplay" allowfullscreen frameborder="0"></iframe>'
      );
    } else {
      alert("No video assigned.");
      return false;
    }
  }

  $(".video-modal").click(function (e) {
    e.preventDefault();
    var $theVideo = videoId($(this));
    if ($theVideo) {
      $("body")
        .append(
          '<div class="active" id="video-wrap"><span class="video-overlay"></span><div class="video-container"><div class="video-mainWrapper">' +
            $theVideo +
            '</div></div><button class="close-video"><span></span></button></div>'
        )
        .addClass("active");
    }
  });

  $(document).on("click", ".close-video, .video-overlay", function () {
    $("#video-wrap").remove();
  });
}
// Video Modal-------------------End

const exampleText = ["Creative", "detail-oriented"];
const exampleTyping = new AutoTyping("#heroTitleBg", exampleText, {
  typeSpeed: 100,
  deleteSpeed: 100,
  waitBeforeDelete: 1000,
  waitBetweenWords: 250,
});
exampleTyping.start();

// ************************************************************************************************************
// ************************************************************************************************************

//                                              Custom Cursor-----START

// ************************************************************************************************************
// ************************************************************************************************************

/* Custom Cursor Start */
function customCursor() {
  var cursor = $(".cursor"),
    follower = $(".cursor-follower");
  var posX = 0,
    posY = 0;
  var mouseX = 0,
    mouseY = 0;
  TweenMax.to({}, 0.01, {
    repeat: -1,
    onRepeat: function () {
      posX += (mouseX - posX) / 9;
      posY += (mouseY - posY) / 9;

      TweenMax.set(follower, {
        css: {
          left: posX - 0,
          top: posY - 0,
        },
      });

      TweenMax.set(cursor, {
        css: {
          left: mouseX,
          top: mouseY,
        },
      });
    },
  });
  $(document).on("mousemove", function (e) {
    mouseX = e.clientX;
    mouseY = e.clientY;
  });

  //
  $("a[href]").on("mouseenter", function () {
    cursor.addClass("hovered");
    follower.addClass("hovered");
  });
  $("a[href]").on("mouseleave", function () {
    cursor.removeClass("hovered");
    follower.removeClass("hovered");
  });

  //
  $(".drag--img").on("mouseenter", function () {
    cursor.addClass("img-hover");
    follower.addClass("img-hover");
  });
  $(".drag--img").on("mouseleave", function () {
    cursor.removeClass("img-hover");
    follower.removeClass("img-hover");
  });

  $(".no--cursor").on("mouseenter", function () {
    cursor.addClass("no-cursor");
    follower.addClass("no-cursor");
  });
  $(".no--cursor").on("mouseleave", function () {
    cursor.removeClass("no-cursor");
    follower.removeClass("no-cursor");
  });

  //
  $(".drag--img--white").on("mouseenter", function () {
    cursor.addClass("white-bg");
    follower.addClass("white-bg");
  });
  $(".drag--img--white").on("mouseleave", function () {
    cursor.removeClass("white-bg");
    follower.removeClass("white-bg");
  });

  //
  $(".white--cursor").on("mouseenter", function () {
    cursor.addClass("white-col");
    follower.addClass("white-col");
  });
  $(".white--cursor").on("mouseleave", function () {
    cursor.removeClass("white-col");
    follower.removeClass("white-col");
  });

  //
  // $(".js-testimonialsSlider").on("mouseenter", function() {
  //     cursor.addClass("white-bg");
  //     follower.addClass("white-bg");
  // });
  // $(".js-testimonialsSlider").on("mouseleave", function() {
  //     cursor.removeClass("white-col");
  //     follower.removeClass("white-col");
  // });
}

$(document).ready(function () {
  customCursor();
});
/* Custom Cursor End */

// ************************************************************************************************************
// ************************************************************************************************************

//                                              Custom Cursor-----END

// ************************************************************************************************************
// ************************************************************************************************************

// ************************************************************************************************************
// ************************************************************************************************************

//                                              Smmoth Scroll-----START

// ************************************************************************************************************
// ************************************************************************************************************

(function () {
  // Scroll Variables (tweakable)
  var defaultOptions = {
    // Scrolling Core
    frameRate: 150, // [Hz]
    animationTime: 600, // [ms]
    stepSize: 80, // [px]

    // Pulse (less tweakable)
    // ratio of "tail" to "acceleration"
    pulseAlgorithm: true,
    pulseScale: 4,
    pulseNormalize: 1,

    // Acceleration
    accelerationDelta: 50, // 50
    accelerationMax: 3, // 3

    // Keyboard Settings
    keyboardSupport: true, // option
    arrowScroll: 50, // [px]

    // Other
    fixedBackground: true,
    excluded: "",
  };

  var options = defaultOptions;

  // Other Variables
  var isExcluded = false;
  var isFrame = false;
  var direction = { x: 0, y: 0 };
  var initDone = false;
  var root = document.documentElement;
  var activeElement;
  var observer;
  var refreshSize;
  var deltaBuffer = [];
  var deltaBufferTimer;
  var isMac = /^Mac/.test(navigator.platform);

  var key = {
    left: 37,
    up: 38,
    right: 39,
    down: 40,
    spacebar: 32,
    pageup: 33,
    pagedown: 34,
    end: 35,
    home: 36,
  };
  var arrowKeys = { 37: 1, 38: 1, 39: 1, 40: 1 };

  /***********************************************
   * INITIALIZE
   ***********************************************/

  /**
   * Tests if smooth scrolling is allowed. Shuts down everything if not.
   */
  function initTest() {
    if (options.keyboardSupport) {
      addEvent("keydown", keydown);
    }
  }

  /**
   * Sets up scrolls array, determines if frames are involved.
   */
  function init() {
    if (initDone || !document.body) return;

    initDone = true;

    var body = document.body;
    var html = document.documentElement;
    var windowHeight = window.innerHeight;
    var scrollHeight = body.scrollHeight;

    // check compat mode for root element
    root = document.compatMode.indexOf("CSS") >= 0 ? html : body;
    activeElement = body;

    initTest();

    // Checks if this script is running in a frame
    if (top != self) {
      isFrame = true;
    } else if (
      /**
       * Safari 10 fixed it, Chrome fixed it in v45:
       * This fixes a bug where the areas left and right to
       * the content does not trigger the onmousewheel event
       * on some pages. e.g.: html, body { height: 100% }
       */
      isOldSafari &&
      scrollHeight > windowHeight &&
      (body.offsetHeight <= windowHeight || html.offsetHeight <= windowHeight)
    ) {
      var fullPageElem = document.createElement("div");
      fullPageElem.style.cssText =
        "position:absolute; z-index:-10000; " +
        "top:0; left:0; right:0; height:" +
        root.scrollHeight +
        "px";
      document.body.appendChild(fullPageElem);

      // DOM changed (throttled) to fix height
      var pendingRefresh;
      refreshSize = function () {
        if (pendingRefresh) return; // could also be: clearTimeout(pendingRefresh);
        pendingRefresh = setTimeout(function () {
          if (isExcluded) return; // could be running after cleanup
          fullPageElem.style.height = "0";
          fullPageElem.style.height = root.scrollHeight + "px";
          pendingRefresh = null;
        }, 500); // act rarely to stay fast
      };

      setTimeout(refreshSize, 10);

      addEvent("resize", refreshSize);

      // TODO: attributeFilter?
      var config = {
        attributes: true,
        childList: true,
        characterData: false,
        // subtree: true
      };

      observer = new MutationObserver(refreshSize);
      observer.observe(body, config);

      if (root.offsetHeight <= windowHeight) {
        var clearfix = document.createElement("div");
        clearfix.style.clear = "both";
        body.appendChild(clearfix);
      }
    }

    // disable fixed background
    if (!options.fixedBackground && !isExcluded) {
      body.style.backgroundAttachment = "scroll";
      html.style.backgroundAttachment = "scroll";
    }
  }

  /**
   * Removes event listeners and other traces left on the page.
   */
  function cleanup() {
    observer && observer.disconnect();
    removeEvent(wheelEvent, wheel);
    removeEvent("mousedown", mousedown);
    removeEvent("keydown", keydown);
    removeEvent("resize", refreshSize);
    removeEvent("load", init);
  }

  /************************************************
   * SCROLLING
   ************************************************/

  var que = [];
  var pending = false;
  var lastScroll = Date.now();

  /**
   * Pushes scroll actions to the scrolling queue.
   */
  function scrollArray(elem, left, top) {
    directionCheck(left, top);

    if (options.accelerationMax != 1) {
      var now = Date.now();
      var elapsed = now - lastScroll;
      if (elapsed < options.accelerationDelta) {
        var factor = (1 + 50 / elapsed) / 2;
        if (factor > 1) {
          factor = Math.min(factor, options.accelerationMax);
          left *= factor;
          top *= factor;
        }
      }
      lastScroll = Date.now();
    }

    // push a scroll command
    que.push({
      x: left,
      y: top,
      lastX: left < 0 ? 0.99 : -0.99,
      lastY: top < 0 ? 0.99 : -0.99,
      start: Date.now(),
    });

    // don't act if there's a pending queue
    if (pending) {
      return;
    }

    var scrollRoot = getScrollRoot();
    var isWindowScroll = elem === scrollRoot || elem === document.body;

    // if we haven't already fixed the behavior,
    // and it needs fixing for this sesh
    if (elem.$scrollBehavior == null && isScrollBehaviorSmooth(elem)) {
      elem.$scrollBehavior = elem.style.scrollBehavior;
      elem.style.scrollBehavior = "auto";
    }

    var step = function (time) {
      var now = Date.now();
      var scrollX = 0;
      var scrollY = 0;

      for (var i = 0; i < que.length; i++) {
        var item = que[i];
        var elapsed = now - item.start;
        var finished = elapsed >= options.animationTime;

        // scroll position: [0, 1]
        var position = finished ? 1 : elapsed / options.animationTime;

        // easing [optional]
        if (options.pulseAlgorithm) {
          position = pulse(position);
        }

        // only need the difference
        var x = (item.x * position - item.lastX) >> 0;
        var y = (item.y * position - item.lastY) >> 0;

        // add this to the total scrolling
        scrollX += x;
        scrollY += y;

        // update last values
        item.lastX += x;
        item.lastY += y;

        // delete and step back if it's over
        if (finished) {
          que.splice(i, 1);
          i--;
        }
      }

      // scroll left and top
      if (isWindowScroll) {
        window.scrollBy(scrollX, scrollY);
      } else {
        if (scrollX) elem.scrollLeft += scrollX;
        if (scrollY) elem.scrollTop += scrollY;
      }

      // clean up if there's nothing left to do
      if (!left && !top) {
        que = [];
      }

      if (que.length) {
        requestFrame(step, elem, 1000 / options.frameRate + 1);
      } else {
        pending = false;
        // restore default behavior at the end of scrolling sesh
        if (elem.$scrollBehavior != null) {
          elem.style.scrollBehavior = elem.$scrollBehavior;
          elem.$scrollBehavior = null;
        }
      }
    };

    // start a new queue of actions
    requestFrame(step, elem, 0);
    pending = true;
  }

  /***********************************************
   * EVENTS
   ***********************************************/

  /**
   * Mouse wheel handler.
   * @param {Object} event
   */
  function wheel(event) {
    if (!initDone) {
      init();
    }

    var target = event.target;

    // leave early if default action is prevented
    // or it's a zooming event with CTRL
    if (event.defaultPrevented || event.ctrlKey) {
      return true;
    }

    // leave embedded content alone (flash & pdf)
    if (
      isNodeName(activeElement, "embed") ||
      (isNodeName(target, "embed") && /\.pdf/i.test(target.src)) ||
      isNodeName(activeElement, "object") ||
      target.shadowRoot
    ) {
      return true;
    }

    var deltaX = -event.wheelDeltaX || event.deltaX || 0;
    var deltaY = -event.wheelDeltaY || event.deltaY || 0;

    if (isMac) {
      if (event.wheelDeltaX && isDivisible(event.wheelDeltaX, 120)) {
        deltaX = -120 * (event.wheelDeltaX / Math.abs(event.wheelDeltaX));
      }
      if (event.wheelDeltaY && isDivisible(event.wheelDeltaY, 120)) {
        deltaY = -120 * (event.wheelDeltaY / Math.abs(event.wheelDeltaY));
      }
    }

    // use wheelDelta if deltaX/Y is not available
    if (!deltaX && !deltaY) {
      deltaY = -event.wheelDelta || 0;
    }

    // line based scrolling (Firefox mostly)
    if (event.deltaMode === 1) {
      deltaX *= 40;
      deltaY *= 40;
    }

    var overflowing = overflowingAncestor(target);

    // nothing to do if there's no element that's scrollable
    if (!overflowing) {
      // except Chrome iframes seem to eat wheel events, which we need to
      // propagate up, if the iframe has nothing overflowing to scroll
      if (isFrame && isChrome) {
        // change target to iframe element itself for the parent frame
        Object.defineProperty(event, "target", { value: window.frameElement });
        return parent.wheel(event);
      }
      return true;
    }

    // check if it's a touchpad scroll that should be ignored
    if (isTouchpad(deltaY)) {
      return true;
    }

    // scale by step size
    // delta is 120 most of the time
    // synaptics seems to send 1 sometimes
    if (Math.abs(deltaX) > 1.2) {
      deltaX *= options.stepSize / 120;
    }
    if (Math.abs(deltaY) > 1.2) {
      deltaY *= options.stepSize / 120;
    }

    scrollArray(overflowing, deltaX, deltaY);
    event.preventDefault();
    scheduleClearCache();
  }

  /**
   * Keydown event handler.
   * @param {Object} event
   */
  function keydown(event) {
    var target = event.target;
    var modifier =
      event.ctrlKey ||
      event.altKey ||
      event.metaKey ||
      (event.shiftKey && event.keyCode !== key.spacebar);

    // our own tracked active element could've been removed from the DOM
    if (!document.body.contains(activeElement)) {
      activeElement = document.activeElement;
    }

    // do nothing if user is editing text
    // or using a modifier key (except shift)
    // or in a dropdown
    // or inside interactive elements
    var inputNodeNames = /^(textarea|select|embed|object)$/i;
    var buttonTypes = /^(button|submit|radio|checkbox|file|color|image)$/i;
    if (
      event.defaultPrevented ||
      inputNodeNames.test(target.nodeName) ||
      (isNodeName(target, "input") && !buttonTypes.test(target.type)) ||
      isNodeName(activeElement, "video") ||
      isInsideYoutubeVideo(event) ||
      target.isContentEditable ||
      modifier
    ) {
      return true;
    }

    // [spacebar] should trigger button press, leave it alone
    if (
      (isNodeName(target, "button") ||
        (isNodeName(target, "input") && buttonTypes.test(target.type))) &&
      event.keyCode === key.spacebar
    ) {
      return true;
    }

    // [arrwow keys] on radio buttons should be left alone
    if (
      isNodeName(target, "input") &&
      target.type == "radio" &&
      arrowKeys[event.keyCode]
    ) {
      return true;
    }

    var shift,
      x = 0,
      y = 0;
    var overflowing = overflowingAncestor(activeElement);

    if (!overflowing) {
      // Chrome iframes seem to eat key events, which we need to
      // propagate up, if the iframe has nothing overflowing to scroll
      return isFrame && isChrome ? parent.keydown(event) : true;
    }

    var clientHeight = overflowing.clientHeight;

    if (overflowing == document.body) {
      clientHeight = window.innerHeight;
    }

    switch (event.keyCode) {
      case key.up:
        y = -options.arrowScroll;
        break;
      case key.down:
        y = options.arrowScroll;
        break;
      case key.spacebar: // (+ shift)
        shift = event.shiftKey ? 1 : -1;
        y = -shift * clientHeight * 0.9;
        break;
      case key.pageup:
        y = -clientHeight * 0.9;
        break;
      case key.pagedown:
        y = clientHeight * 0.9;
        break;
      case key.home:
        if (overflowing == document.body && document.scrollingElement)
          overflowing = document.scrollingElement;
        y = -overflowing.scrollTop;
        break;
      case key.end:
        var scroll = overflowing.scrollHeight - overflowing.scrollTop;
        var scrollRemaining = scroll - clientHeight;
        y = scrollRemaining > 0 ? scrollRemaining + 10 : 0;
        break;
      case key.left:
        x = -options.arrowScroll;
        break;
      case key.right:
        x = options.arrowScroll;
        break;
      default:
        return true; // a key we don't care about
    }

    scrollArray(overflowing, x, y);
    event.preventDefault();
    scheduleClearCache();
  }

  /**
   * Mousedown event only for updating activeElement
   */
  function mousedown(event) {
    activeElement = event.target;
  }

  /***********************************************
   * OVERFLOW
   ***********************************************/

  var uniqueID = (function () {
    var i = 0;
    return function (el) {
      return el.uniqueID || (el.uniqueID = i++);
    };
  })();

  var cacheX = {}; // cleared out after a scrolling session
  var cacheY = {}; // cleared out after a scrolling session
  var clearCacheTimer;
  var smoothBehaviorForElement = {};

  //setInterval(function () { cache = {}; }, 10 * 1000);

  function scheduleClearCache() {
    clearTimeout(clearCacheTimer);
    clearCacheTimer = setInterval(function () {
      cacheX = cacheY = smoothBehaviorForElement = {};
    }, 1 * 1000);
  }

  function setCache(elems, overflowing, x) {
    var cache = x ? cacheX : cacheY;
    for (var i = elems.length; i--; ) cache[uniqueID(elems[i])] = overflowing;
    return overflowing;
  }

  function getCache(el, x) {
    return (x ? cacheX : cacheY)[uniqueID(el)];
  }

  //  (body)                (root)
  //         | hidden | visible | scroll |  auto  |
  // hidden  |   no   |    no   |   YES  |   YES  |
  // visible |   no   |   YES   |   YES  |   YES  |
  // scroll  |   no   |   YES   |   YES  |   YES  |
  // auto    |   no   |   YES   |   YES  |   YES  |

  function overflowingAncestor(el) {
    var elems = [];
    var body = document.body;
    var rootScrollHeight = root.scrollHeight;
    do {
      var cached = getCache(el, false);
      if (cached) {
        return setCache(elems, cached);
      }
      elems.push(el);
      if (rootScrollHeight === el.scrollHeight) {
        var topOverflowsNotHidden =
          overflowNotHidden(root) && overflowNotHidden(body);
        var isOverflowCSS = topOverflowsNotHidden || overflowAutoOrScroll(root);
        if (
          (isFrame && isContentOverflowing(root)) ||
          (!isFrame && isOverflowCSS)
        ) {
          return setCache(elems, getScrollRoot());
        }
      } else if (isContentOverflowing(el) && overflowAutoOrScroll(el)) {
        return setCache(elems, el);
      }
    } while ((el = el.parentElement));
  }

  function isContentOverflowing(el) {
    return el.clientHeight + 10 < el.scrollHeight;
  }

  // typically for <body> and <html>
  function overflowNotHidden(el) {
    var overflow = getComputedStyle(el, "").getPropertyValue("overflow-y");
    return overflow !== "hidden";
  }

  // for all other elements
  function overflowAutoOrScroll(el) {
    var overflow = getComputedStyle(el, "").getPropertyValue("overflow-y");
    return overflow === "scroll" || overflow === "auto";
  }

  // for all other elements
  function isScrollBehaviorSmooth(el) {
    var id = uniqueID(el);
    if (smoothBehaviorForElement[id] == null) {
      var scrollBehavior = getComputedStyle(el, "")["scroll-behavior"];
      smoothBehaviorForElement[id] = "smooth" == scrollBehavior;
    }
    return smoothBehaviorForElement[id];
  }

  /***********************************************
   * HELPERS
   ***********************************************/

  function addEvent(type, fn, arg) {
    window.addEventListener(type, fn, arg || false);
  }

  function removeEvent(type, fn, arg) {
    window.removeEventListener(type, fn, arg || false);
  }

  function isNodeName(el, tag) {
    return el && (el.nodeName || "").toLowerCase() === tag.toLowerCase();
  }

  function directionCheck(x, y) {
    x = x > 0 ? 1 : -1;
    y = y > 0 ? 1 : -1;
    if (direction.x !== x || direction.y !== y) {
      direction.x = x;
      direction.y = y;
      que = [];
      lastScroll = 0;
    }
  }

  if (window.localStorage && localStorage.SS_deltaBuffer) {
    try {
      // #46 Safari throws in private browsing for localStorage
      deltaBuffer = localStorage.SS_deltaBuffer.split(",");
    } catch (e) {}
  }

  function isTouchpad(deltaY) {
    if (!deltaY) return;
    if (!deltaBuffer.length) {
      deltaBuffer = [deltaY, deltaY, deltaY];
    }
    deltaY = Math.abs(deltaY);
    deltaBuffer.push(deltaY);
    deltaBuffer.shift();
    clearTimeout(deltaBufferTimer);
    deltaBufferTimer = setTimeout(function () {
      try {
        // #46 Safari throws in private browsing for localStorage
        localStorage.SS_deltaBuffer = deltaBuffer.join(",");
      } catch (e) {}
    }, 1000);
    var dpiScaledWheelDelta = deltaY > 120 && allDeltasDivisableBy(deltaY); // win64
    var tp =
      !allDeltasDivisableBy(120) &&
      !allDeltasDivisableBy(100) &&
      !dpiScaledWheelDelta;
    if (deltaY < 50) return true;
    return tp;
  }

  function isDivisible(n, divisor) {
    return Math.floor(n / divisor) == n / divisor;
  }

  function allDeltasDivisableBy(divisor) {
    return (
      isDivisible(deltaBuffer[0], divisor) &&
      isDivisible(deltaBuffer[1], divisor) &&
      isDivisible(deltaBuffer[2], divisor)
    );
  }

  function isInsideYoutubeVideo(event) {
    var elem = event.target;
    var isControl = false;
    if (document.URL.indexOf("www.youtube.com/watch") != -1) {
      do {
        isControl =
          elem.classList && elem.classList.contains("html5-video-controls");
        if (isControl) break;
      } while ((elem = elem.parentNode));
    }
    return isControl;
  }

  var requestFrame = (function () {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      function (callback, element, delay) {
        window.setTimeout(callback, delay || 1000 / 60);
      }
    );
  })();

  var MutationObserver =
    window.MutationObserver ||
    window.WebKitMutationObserver ||
    window.MozMutationObserver;

  var getScrollRoot = (function () {
    var SCROLL_ROOT = document.scrollingElement;
    return function () {
      if (!SCROLL_ROOT) {
        var dummy = document.createElement("div");
        dummy.style.cssText = "height:10000px;width:1px;";
        document.body.appendChild(dummy);
        var bodyScrollTop = document.body.scrollTop;
        var docElScrollTop = document.documentElement.scrollTop;
        window.scrollBy(0, 3);
        if (document.body.scrollTop != bodyScrollTop)
          SCROLL_ROOT = document.body;
        else SCROLL_ROOT = document.documentElement;
        window.scrollBy(0, -3);
        document.body.removeChild(dummy);
      }
      return SCROLL_ROOT;
    };
  })();

  /***********************************************
   * PULSE (by Michael Herf)
   ***********************************************/

  /**
   * Viscous fluid with a pulse for part and decay for the rest.
   * - Applies a fixed force over an interval (a damped acceleration), and
   * - Lets the exponential bleed away the velocity over a longer interval
   * - Michael Herf, http://stereopsis.com/stopping/
   */
  function pulse_(x) {
    var val, start, expx;
    // test
    x = x * options.pulseScale;
    if (x < 1) {
      // acceleartion
      val = x - (1 - Math.exp(-x));
    } else {
      // tail
      // the previous animation ended here:
      start = Math.exp(-1);
      // simple viscous drag
      x -= 1;
      expx = 1 - Math.exp(-x);
      val = start + expx * (1 - start);
    }
    return val * options.pulseNormalize;
  }

  function pulse(x) {
    if (x >= 1) return 1;
    if (x <= 0) return 0;

    if (options.pulseNormalize == 1) {
      options.pulseNormalize /= pulse_(1);
    }
    return pulse_(x);
  }

  /***********************************************
   * FIRST RUN
   ***********************************************/

  var userAgent = window.navigator.userAgent;
  var isEdge = /Edge/.test(userAgent); // thank you MS
  var isChrome = /chrome/i.test(userAgent) && !isEdge;
  var isSafari = /safari/i.test(userAgent) && !isEdge;
  var isMobile = /mobile/i.test(userAgent);
  var isIEWin7 = /Windows NT 6.1/i.test(userAgent) && /rv:11/i.test(userAgent);
  var isOldSafari =
    isSafari &&
    (/Version\/8/i.test(userAgent) || /Version\/9/i.test(userAgent));
  var isEnabledForBrowser = (isChrome || isSafari || isIEWin7) && !isMobile;

  var supportsPassive = false;
  try {
    window.addEventListener(
      "test",
      null,
      Object.defineProperty({}, "passive", {
        get: function () {
          supportsPassive = true;
        },
      })
    );
  } catch (e) {}

  var wheelOpt = supportsPassive ? { passive: false } : false;
  var wheelEvent =
    "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

  if (wheelEvent && isEnabledForBrowser) {
    addEvent(wheelEvent, wheel, wheelOpt);
    addEvent("mousedown", mousedown);
    addEvent("load", init);
  }

  /***********************************************
   * PUBLIC INTERFACE
   ***********************************************/

  function SmoothScroll(optionsToSet) {
    for (var key in optionsToSet)
      if (defaultOptions.hasOwnProperty(key)) options[key] = optionsToSet[key];
  }
  SmoothScroll.destroy = cleanup;

  if (window.SmoothScrollOptions)
    // async API
    SmoothScroll(window.SmoothScrollOptions);

  if (typeof define === "function" && define.amd)
    define(function () {
      return SmoothScroll;
    });
  else if ("object" == typeof exports) module.exports = SmoothScroll;
  else window.SmoothScroll = SmoothScroll;
})();

// ************************************************************************************************************
// ************************************************************************************************************

//                                              Smmoth Scroll-----END

// ************************************************************************************************************
// ************************************************************************************************************

// ************************************************************************************************************
// ************************************************************************************************************

//                                            GSAP Animation-----START

// ************************************************************************************************************
// ************************************************************************************************************
//Loader---Start
function loader() {
  if (
    $(window).scrollTop() > 0 ||
    sw < 1199 ||
    (sw < 1400 && sw > 1300 && sh > 900)
  ) {
    gsap
      .timeline({
        paused: false,
        repeat: 0,
      })
      .to(
        ".loader__title .title1",
        {
          opacity: 1,

          duration: 0.5,
          ease: "power4.easeOut",
        },
        0
      )
      .to(
        ".loader__title .title2",
        {
          opacity: 1,

          duration: 0.5,
          ease: "power4.easeOut",
        },
        0.5
      )
      .to(
        ".loader__dg__Logo",
        {
          opacity: 1,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        1
      )
      .to(
        ".loader__dg__Logo",
        {
          opacity: 0,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        1.5
      )
      .to(
        ".loader__dg__Logo",
        {
          opacity: 1,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        2
      )
      .to(
        ".loader__dg__Logo",
        {
          opacity: 0,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        2.5
      )
      .to(
        ".loader__dgx__Logo",
        {
          opacity: 1,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        3
      )
      .to(
        ".loader__dgx__Logo",
        {
          opacity: 0,
          top: "50%",
          scale: 0.25,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        3.5
      )
      .to(
        ".loader__x__Logo",
        {
          transform: "translate(-50%, -50%) scale(1)",
          opacity: 1,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        3.5
      )
      .to(
        ".loader__title .title1",
        {
          opacity: 0,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        3.5
      )
      .to(
        ".loader__title .title2",
        {
          opacity: 0,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        3.5
      )
      .to(
        ".loader__x__Logo",
        {
          opacity: 0,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        4
      )
      .to(
        ".loader__x__Logo",
        {
          opacity: 1,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        4.5
      )
      .to(
        ".loader__x__Logo svg path",
        {
          fill: "transparent",
          duration: 0.25,
          ease: "power4.easeOut",
        },
        5
      )

      .to(
        ".loader__x__Logo svg path",
        {
          fill: "#fff",
          duration: 0.25,
          ease: "power4.easeOut",
        },
        5.75
      )
      .to(
        ".loader__x__Logo svg path",
        {
          opacity: 0,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        6.75
      )
      .to(
        ".loader__bg",
        {
          // left: "11.25%",
          //transform: "translateY(-50%) translateX(0) scale(1)",
          opacity: 0,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        7.5
      )
      .to(
        ".loader__bg",
        {
          opacity: 0,
          duration: 0.25,
          ease: "power4.easeOut",
        },
        8
      )
      .to(
        ".heroTitle .title1",
        {
          opacity: 1,
          transform: "translateX(0)",
          duration: 0.5,
          ease: "power4.easeOut",
        },
        8
      )
      .to(
        ".heroTitle .title2",
        {
          opacity: 1,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        8
      )
      .to(
        ".heroTitle .title3",
        {
          opacity: 1,
          transform: "translateX(0)",
          duration: 0.5,
          ease: "power4.easeOut",
        },
        8
      )
      .to(
        ".heroSection .circleLogoWrapper",
        {
          opacity: 1,
          transform: "scale(1)",
          duration: 0.5,
          ease: "power4.easeOut",
        },
        8
      )
      .to(
        ".loader__bg",
        {
          display: "none",
          ease: "power4.easeOut",
        },
        8.5
      )
      .to(
        ".loader__title",
        {
          display: "none",
          ease: "power4.easeOut",
        },
        8.5
      )
      .to(
        ".loader__dg__Logo",
        {
          display: "none",
          ease: "power4.easeOut",
        },
        8.5
      )
      .to(
        ".loader__dgx__Logo",
        {
          display: "none",
          ease: "power4.easeOut",
        },
        8.5
      )
      .to(
        ".loader__x__Logo",
        {
          display: "none",
          ease: "power4.easeOut",
        },
        8.5
      )
      .to(
        "html",
        {
          className: "homepage",
        },
        9.5
      );
  } else {
    gsap
      .timeline({
        paused: false,
        repeat: 0,
      })
      .to(
        ".loader__title .title1",
        {
          opacity: 1,

          duration: 0.5,
          ease: "power4.easeOut",
        },
        0
      )
      .to(
        ".loader__title .title2",
        {
          opacity: 1,

          duration: 0.5,
          ease: "power4.easeOut",
        },
        0.5
      )
      .to(
        ".loader__dg__Logo",
        {
          opacity: 1,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        1
      )
      .to(
        ".loader__dg__Logo",
        {
          opacity: 0,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        1.5
      )
      .to(
        ".loader__dg__Logo",
        {
          opacity: 1,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        2
      )
      .to(
        ".loader__dg__Logo",
        {
          opacity: 0,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        2.5
      )
      .to(
        ".loader__dgx__Logo",
        {
          opacity: 1,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        3
      )
      .to(
        ".loader__dgx__Logo",
        {
          opacity: 0,
          top: "50%",
          scale: 0.25,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        3.5
      )
      .to(
        ".loader__x__Logo",
        {
          transform: "translate(-50%, -50%) scale(1)",
          opacity: 1,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        3.5
      )
      .to(
        ".loader__title .title1",
        {
          opacity: 0,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        3.5
      )
      .to(
        ".loader__title .title2",
        {
          opacity: 0,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        3.5
      )
      .to(
        ".loader__x__Logo",
        {
          opacity: 0,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        4
      )
      .to(
        ".loader__x__Logo",
        {
          opacity: 1,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        4.5
      )
      .to(
        ".loader__x__Logo svg path",
        {
          fill: "transparent",
          duration: 0.25,
          ease: "power4.easeOut",
        },
        5
      )

      .to(
        ".loader__x__Logo svg path",
        {
          fill: "#fff",
          duration: 0.25,
          ease: "power4.easeOut",
        },
        5.75
      )
      .to(
        ".loader__x__Logo svg path",
        {
          opacity: 0,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        6.75
      )
      .to(
        ".loader__bg",
        {
          left: "11.25%",
          transform: "translateY(-50%) translateX(0) scale(1)",
          duration: 0.5,
          ease: "power4.easeOut",
        },
        7.5
      )
      .to(
        ".loader__bg",
        {
          opacity: 0,
          duration: 0.25,
          ease: "power4.easeOut",
        },
        8
      )
      .to(
        ".heroTitle .title1",
        {
          opacity: 1,
          transform: "translateX(0)",
          duration: 0.5,
          ease: "power4.easeOut",
        },
        8
      )
      .to(
        ".heroTitle .title2",
        {
          opacity: 1,
          duration: 0.5,
          ease: "power4.easeOut",
        },
        8
      )
      .to(
        ".heroTitle .title3",
        {
          opacity: 1,
          transform: "translateX(0)",
          duration: 0.5,
          ease: "power4.easeOut",
        },
        8
      )
      .to(
        ".heroSection .circleLogoWrapper",
        {
          opacity: 1,
          transform: "scale(1)",
          duration: 0.5,
          ease: "power4.easeOut",
        },
        8
      )
      .to(
        ".loader__bg",
        {
          display: "none",
          ease: "power4.easeOut",
        },
        8.5
      )
      .to(
        ".loader__title",
        {
          display: "none",
          ease: "power4.easeOut",
        },
        8.5
      )
      .to(
        ".loader__dg__Logo",
        {
          display: "none",
          ease: "power4.easeOut",
        },
        8.5
      )
      .to(
        ".loader__dgx__Logo",
        {
          display: "none",
          ease: "power4.easeOut",
        },
        8.5
      )
      .to(
        ".loader__x__Logo",
        {
          display: "none",
          ease: "power4.easeOut",
        },
        8.5
      )
      .to(
        "html",
        {
          className: "homepage",
        },
        9.5
      );
  }
}

setTimeout(function () {
  loader();
}, 2000);
//Loader---End

//Hero Section---Start
setTimeout(() => {
  const rotatingText = document.querySelector(".circleLogoText");

  const t = gsap.to(rotatingText, {
    rotation: 360,
    duration: 40,
    ease: "none",
    repeat: -1,
  });
  t.iteration(1000);

  const speedFactor = 5;
  let tl;

  var rotate = gsap.timeline({
    scrollTrigger: {
      trigger: "html",
      start: "top top",
      end: "+=10000",
      onUpdate: (self) => {
        tl && tl.kill();
        tl = gsap
          .timeline()
          .to(t, { timeScale: speedFactor * self.direction, duration: 0.1 })
          .to(t, { timeScale: self.direction, duration: 1 }, "+=0.5");
      },
    },
  });
}, 1500);
//Hero Section---End

if (sw > 1199) {
  if (sw < 1400 && sw > 1300 && sh > 900) {
  } else {
    Splitting();

    //Video Section---Start
    setTimeout(() => {
      // Video Animation----START

      const videoIn = gsap.timeline();
      videoIn.to(
        ".videoSection",
        {
          opacity: 1,
          duration: 1,
        },

        0
      );
      ScrollTrigger.create({
        duration: "100%",
        animation: videoIn,
        trigger: ".videoSection",
        start: "top 80%",
        end: "top 50%",
        scrub: 0.5,
        pin: false,
        anticipatePin: 0.5,
      });

      const videoScale = gsap.timeline();
      videoScale
        .to(
          ".videoSection",
          {
            padding: "0",
            duration: 1,
          },

          0
        )
        .to(
          ".videoSection",
          {
            padding: "0",
            duration: 1,
          },

          1
        )
        .to(
          ".videoSection",
          {
            padding: "0 5em 5em 5em",
            duration: 1,
          },

          2
        );
      ScrollTrigger.create({
        duration: "100%",
        animation: videoScale,
        trigger: ".videoSection",
        start: "top 0%",
        end: "+=1500",
        scrub: 0.5,
        pin: true,
        anticipatePin: 0.5,
      });

      // Video Animation----END
    }, 1500);

    //Video Section---End

    //Services Section---Start
    setTimeout(() => {
      // services Animation----START
      const servicesTmeline = gsap.timeline();
      servicesTmeline
        // .fromTo(
        //   ".serviceOne .serviceDescrip",
        //   {
        //     autoAlpha: 0,
        //     transform: "translateY(25%)",
        //     duration: 1,
        //   },
        //   {
        //     autoAlpha: 1,
        //     transform: "translateY(0%)",
        //     duration: 1,
        //   },
        //   0
        // )
        // .fromTo(
        //   ".serviceOne .rightWrapper .bg__element",
        //   {
        //     "clip-path": " inset(0 0% 0 0)",
        //     duration: 1,
        //   },
        //   {
        //     "clip-path": " inset(0 0% 100% 0%)",
        //     duration: 1,
        //   },
        //   0
        // )
        .to(
          ".serviceOne .serviceDescrip",
          {
            autoAlpha: 0,
            transform: "translateY(-25%)",
            duration: 1,
          },
          0.5
        )
        .to(
          ".serviceOne .rightWrapper",
          {
            "clip-path": " inset(0% 0% 100% 0%)",
            duration: 2,
          },
          0.5
        )
        .fromTo(
          ".serviceTwo .serviceDescrip",
          {
            autoAlpha: 0,
            transform: "translateY(25%)",
            duration: 1,
          },
          {
            autoAlpha: 1,
            transform: "translateY(0%)",
            duration: 1,
          },
          1.25
        )

        .to(
          ".serviceTwo .serviceDescrip",
          {
            autoAlpha: 0,
            transform: "translateY(-25%)",
            duration: 1,
          },
          2.25
        )
        .to(
          ".serviceTwo .rightWrapper",
          {
            "clip-path": " inset(0% 0% 100% 0%)",
            duration: 2,
          },
          2.25
        )
        .fromTo(
          ".serviceThree .serviceDescrip",
          {
            autoAlpha: 0,
            transform: "translateY(25%)",
            duration: 1,
          },
          {
            autoAlpha: 1,
            transform: "translateY(0%)",
            duration: 1,
          },
          3
        )
        .to(
          ".serviceThree .serviceDescrip",
          {
            autoAlpha: 0,
            transform: "translateY(-25%)",
            duration: 1,
          },
         4
        )
        .to(
          ".serviceThree .rightWrapper",
          {
            "clip-path": " inset(0% 0% 100% 0%)",
            duration: 2,
          },
         4
        )
        .fromTo(
          ".serviceFour .serviceDescrip",
          {
            autoAlpha: 0,
            transform: "translateY(25%)",
            duration: 1,
          },
          {
            autoAlpha: 1,
            transform: "translateY(0%)",
            duration: 1,
          },
          4.75
        )
        .to(
          ".serviceFour .serviceDescrip",
          {
            autoAlpha: 0,
            transform: "translateY(-25%)",
            duration: 1,
          },
         5.75
        )
        .to(
          ".serviceFour .rightWrapper",
          {
            "clip-path": " inset(0% 0% 100% 0%)",
            duration: 2,
          },
         5.75
        )
        .fromTo(
          ".serviceFive .serviceDescrip",
          {
            autoAlpha: 0,
            transform: "translateY(25%)",
            duration: 1,
          },
          {
            autoAlpha: 1,
            transform: "translateY(0%)",
            duration: 1,
          },
          6.5
        )
        .to(
          ".serviceFive .serviceDescrip",
          {
            autoAlpha: 0,
            transform: "translateY(-25%)",
            duration: 1,
          },
          7.5
        )
        .to(
          ".serviceFive .rightWrapper",
          {
            "clip-path": " inset(0% 0% 100% 0%)",
            duration: 2,
          },
          7.5
        )
        .fromTo(
          ".serviceSix .serviceDescrip",
          {
            autoAlpha: 0,
            transform: "translateY(25%)",
            duration: 1,
          },
          {
            autoAlpha: 1,
            transform: "translateY(0%)",
            duration: 1,
          },
          8.25
        );
      // .fromTo(
      //   ".serviceTwo .rightWrapper .bg__element",
      //   {
      //     "clip-path": " inset(0 0% 0 0)",
      //     duration: 1,
      //   },
      //   {
      //     "clip-path": " inset(0 0% 100% 0%)",
      //     duration: 1,
      //   },
      //   1
      // )
      // .to(
      //   ".serviceTwo .serviceDescrip",
      //   {
      //     autoAlpha: 0,
      //     transform: "translateY(-25%)",
      //     duration: 1,
      //   },
      //   3
      // )
      // .to(
      //   ".serviceTwo .rightWrapper",
      //   {
      //     "clip-path": " inset(0 0% 100% 0%)",
      //     duration: 1,
      //   },
      //   3
      // )
      // .fromTo(
      //   ".serviceThree .serviceDescrip",
      //   {
      //     autoAlpha: 0,
      //     transform: "translateY(25%)",
      //     duration: 1,
      //   },
      //   {
      //     autoAlpha: 1,
      //     transform: "translateY(0%)",
      //     duration: 1,
      //   },
      //   4
      // )
      // .fromTo(
      //   ".serviceThree .rightWrapper .bg__element",
      //   {
      //     "clip-path": " inset(0 0% 0 0)",
      //     duration: 1,
      //   },
      //   {
      //     "clip-path": " inset(0 0% 100% 0%)",
      //     duration: 1,
      //   },
      //   4
      // )
      // .to(
      //   ".serviceThree .serviceDescrip",
      //   {
      //     autoAlpha: 0,
      //     transform: "translateY(-25%)",
      //     duration: 1,
      //   },
      //   6
      // )
      // .to(
      //   ".serviceThree .rightWrapper",
      //   {
      //     "clip-path": " inset(0 0% 100% 0%)",
      //     duration: 1,
      //   },
      //   6
      // )
      // .fromTo(
      //   ".serviceFour .serviceDescrip",
      //   {
      //     autoAlpha: 0,
      //     transform: "translateY(25%)",
      //     duration: 1,
      //   },
      //   {
      //     autoAlpha: 1,
      //     transform: "translateY(0%)",
      //     duration: 1,
      //   },
      //   7
      // )
      // .fromTo(
      //   ".serviceFour .rightWrapper .bg__element",
      //   {
      //     "clip-path": " inset(0 0% 0 0)",
      //     duration: 1,
      //   },
      //   {
      //     "clip-path": " inset(0 0% 100% 0%)",
      //     duration: 1,
      //   },
      //   7
      // )
      // .to(
      //   ".serviceFour .serviceDescrip",
      //   {
      //     autoAlpha: 0,
      //     transform: "translateY(-25%)",
      //     duration: 1,
      //   },
      //   9
      // )
      // .to(
      //   ".serviceFour .rightWrapper",
      //   {
      //     "clip-path": " inset(0 0% 100% 0%)",
      //     duration: 1,
      //   },
      //   9
      // )
      // .fromTo(
      //   ".serviceFive .serviceDescrip",
      //   {
      //     autoAlpha: 0,
      //     transform: "translateY(25%)",
      //     duration: 1,
      //   },
      //   {
      //     autoAlpha: 1,
      //     transform: "translateY(0%)",
      //     duration: 1,
      //   },
      //   10
      // )
      // .fromTo(
      //   ".serviceFive .rightWrapper .bg__element",
      //   {
      //     "clip-path": " inset(0 0% 0 0)",
      //     duration: 1,
      //   },
      //   {
      //     "clip-path": " inset(0 0% 100% 0%)",
      //     duration: 1,
      //   },
      //   10
      // )
      // .to(
      //   ".serviceFive .serviceDescrip",
      //   {
      //     autoAlpha: 0,
      //     transform: "translateY(-25%)",
      //     duration: 1,
      //   },
      //   12
      // )
      // .to(
      //   ".serviceFive .rightWrapper",
      //   {
      //     "clip-path": " inset(0 0% 100% 0%)",
      //     duration: 1,
      //   },
      //   12
      // )
      // .fromTo(
      //   ".serviceSix .serviceDescrip",
      //   {
      //     autoAlpha: 0,
      //     transform: "translateY(25%)",
      //     duration: 1,
      //   },
      //   {
      //     autoAlpha: 1,
      //     transform: "translateY(0%)",
      //     duration: 1,
      //   },
      //   13
      // )
      // .fromTo(
      //   ".serviceSix .rightWrapper .bg__element",
      //   {
      //     "clip-path": " inset(0 0% 0 0)",
      //     duration: 1,
      //   },
      //   {
      //     "clip-path": " inset(0 0% 100% 0%)",
      //     duration: 1,
      //   },
      //   13
      // );
      // .to(
      //   ".serviceSix .serviceDescrip",
      //   {
      //     autoAlpha: 0,
      //     transform: "translateY(-25%)",
      //     duration: 1,
      //   },
      //   15
      // )
      // .to(
      //   ".serviceSix .rightWrapper",
      //   {
      //     "clip-path": " inset(0 0% 100% 0%)",
      //     duration: 1,
      //   },
      //   15
      // );

      ScrollTrigger.create({
        duration: "100%",
        animation: servicesTmeline,
        trigger: ".servicesSection",
        start: "top top",
        end: "+=5500",
        scrub: 1.5,
        pin: true,
        anticipatePin: 0.5,
      });
      const servicesHeadIn = gsap.timeline();
      servicesHeadIn.to(
        ".servicesSection__head",
        {
          opacity: 1,
          transform: " translateX(0%)",
          duration: 1,
        },

        0
      );
      ScrollTrigger.create({
        duration: "100%",
        animation: servicesHeadIn,
        trigger: ".servicesSection",
        start: "top 80%",
        end: "top 50%",
        scrub: 1.5,
        pin: false,
        anticipatePin: 0.5,
      });

      const servicesrightSecIn = gsap.timeline();
      servicesrightSecIn.to(
        ".serviceWrapper",
        {
          transform: " translateY(0%)",
          duration: 1,
        },

        0
      );
      ScrollTrigger.create({
        duration: "100%",
        animation: servicesrightSecIn,
        trigger: ".servicesSection",
        start: "top 80%",
        end: "top 45%",
        scrub: 1.5,
        pin: false,
        anticipatePin: 0.5,
      });
      // const servicesBgIn = gsap.timeline();
      // servicesBgIn
      //   .fromTo(
      //     ".servicesSection__bgIn",
      //     {
      //       opacity: 0,
      //       duration: 0.5,
      //     },
      //     {
      //       opacity: 1,
      //       duration: 0.5,
      //     },

      //     0
      //   )
      //   .fromTo(
      //     ".servicesSection__bgOut",
      //     {
      //       opacity: 0,
      //       duration: 0.5,
      //     },
      //     {
      //       opacity: 1,
      //       duration: 0.5,
      //     },

      //     0
      //   );
      // ScrollTrigger.create({
      //   duration: "100%",
      //   animation: servicesBgIn,
      //   trigger: ".servicesSection",
      //   start: "top 80%",
      //   end: "top 70%",
      //   scrub: 1.5,
      //   pin: false,
      //   anticipatePin: 0.5,
      // });

      // const servicesBgOut = gsap.timeline();
      // servicesBgOut
      //   .fromTo(
      //     ".servicesSection__bgIn",
      //     {
      //       opacity: 1,
      //       duration: 0.5,
      //     },
      //     {
      //       opacity: 0,
      //       duration: 0.5,
      //     },
      //     0
      //   )
      //   .fromTo(
      //     ".servicesSection__bgOut",
      //     {
      //       opacity: 1,
      //       duration: 0.5,
      //     },
      //     {
      //       opacity: 0,
      //       duration: 0.5,
      //     },
      //     0
      //   );
      // ScrollTrigger.create({
      //   duration: "100%",
      //   animation: servicesBgOut,
      //   trigger: ".servicesSection",
      //   start: "bottom 97%",
      //   end: "bottom 70%",
      //   scrub: 1.5,
      //   pin: false,
      //   anticipatePin: 0.5,
      // });

      // services Animation----END
    }, 1500);
    //Services Section---End

    //Work Section---Start
    setTimeout(() => {
      // Work Section Animation----START
      const worksTimeline = gsap.timeline();
      worksTimeline.fromTo(
        ".workCardsWrapper",
        {
          transform: "translateY(0%)",
          duration: 1,
        },
        {
          transform: "translateY(-180%)",
          duration: 1,
        },
        0.5
      );
      // .fromTo(
      //   ".workCardsWrapper .cardOne",
      //   {
      //     scale: 1,
      //     duration: 1,
      //   },
      //   {
      //     scale: 0,
      //     duration: 1,
      //   },
      //   0.5
      // )
      // .fromTo(
      //   ".workCardsWrapper .cardTwo",
      //   {
      //     top: "calc(50% + 33em)",
      //     duration: 1,
      //   },
      //   {
      //     top: "calc(50% + 0em)",
      //     duration: 1,
      //   },
      //   0.5
      // )
      // .fromTo(
      //   ".workCardsWrapper .cardThree",
      //   {
      //     top: "calc(100% + 32em)",
      //     duration: 1,
      //   },
      //   {
      //     top: "calc(50% + 32em)",
      //     duration: 1,
      //   },
      //   0.5
      // )
      // .fromTo(
      //   ".workCardsWrapper .cardTwo",
      //   {
      //     scale: 1,
      //     autoAlpha: 1,
      //     duration: 1,
      //   },
      //   {
      //     scale: 0,
      //     autoAlpha: 0,
      //     duration: 1,
      //   },
      //   2
      // )
      // .fromTo(
      //   ".workCardsWrapper .cardThree",
      //   {
      //     top: "calc(50% + 32em)",
      //     duration: 1,
      //   },
      //   {
      //     top: "calc(50% + 0em)",
      //     duration: 1,
      //   },
      //   2
      // )
      // .fromTo(
      //   ".workCardsWrapper .cardFour",
      //   {
      //     top: "calc(100% + 32em)",
      //     duration: 1,
      //   },
      //   {
      //     top: "calc(50% + 32em)",
      //     duration: 1,
      //   },
      //   2
      // )
      // .fromTo(
      //   ".workCardsWrapper .cardThree",
      //   {
      //     scale: 1,
      //     autoAlpha: 1,
      //     duration: 1,
      //   },
      //   {
      //     scale: 0,
      //     autoAlpha: 0,
      //     duration: 1,
      //   },
      //   3.5
      // )
      // .fromTo(
      //   ".workCardsWrapper .cardFour",
      //   {
      //     top: "calc(50% + 32em)",
      //     duration: 1,
      //   },
      //   {
      //     top: "calc(50% + 0em)",
      //     duration: 1,
      //   },
      //   3.5
      // );

      ScrollTrigger.create({
        duration: "100%",
        animation: worksTimeline,
        trigger: ".workSection",
        start: "top top",
        end: "+=2500",
        scrub: 1.5,
        pin: true,
        anticipatePin: 0.5,
      });

      // // Work Section Animation----END

      const workCardIn = gsap.timeline();
      workCardIn
        .to(
          ".rightCol",
          {
            x: "0",
            duration: 1,
          },

          0
        )
        .to(
          ".workSection .bottomBox",
          {
            x: "0",
            duration: 1,
          },

          0
        );
      ScrollTrigger.create({
        duration: "100%",
        animation: workCardIn,
        trigger: ".workSection",
        start: "top 80%",
        end: "top 0%",
        scrub: 1.5,
        pin: false,
        anticipatePin: 0.5,
      });
    }, 1500);

    //Work Section---End

    //Testimonial Section---Start
    setTimeout(() => {
      const testimonialSliderIn = gsap.timeline();
      testimonialSliderIn.to(
        ".testimonialSlider",
        {
          x: "0",
          duration: 1,
        },

        0
      );
      ScrollTrigger.create({
        duration: "100%",
        animation: testimonialSliderIn,
        trigger: ".testimonialsSec",
        start: "top 80%",
        end: "top 45%",
        scrub: 1.5,
        pin: false,
        anticipatePin: 0.5,
      });
    }, 1500);
    //Testimonial Section---End

    //Blog Section---Start
    setTimeout(() => {
      const blogSliderIn = gsap.timeline();
      blogSliderIn.to(
        ".blogSlider",
        {
          x: "0",
          duration: 1,
        },

        0
      );
      ScrollTrigger.create({
        duration: "100%",
        animation: blogSliderIn,
        trigger: ".blogSection",
        start: "top 80%",
        end: "top 45%",
        scrub: 1.5,
        pin: false,
        anticipatePin: 0.5,
      });
    }, 1500);
    //Blog Section---End

    //Footer Section---Start
    setTimeout(() => {
      const rotatingText2 = document.querySelector(".footer .circleLogoText");

      const tt = gsap.to(rotatingText2, {
        rotation: 360,
        duration: 40,
        ease: "none",
        repeat: -1,
      });
      tt.iteration(1000);

      const speedFactor1 = 5;
      let tl2;

      var rotate2 = gsap.timeline({
        scrollTrigger: {
          trigger: "html",
          start: "top top",
          end: "+=10000",
          onUpdate: (self2) => {
            tl2 && tl2.kill();
            tl2 = gsap
              .timeline()
              .to(tt, {
                timeScale: speedFactor1 * self2.direction,
                duration: 0.1,
              })
              .to(tt, { timeScale: self2.direction, duration: 1 }, "+=0.5");
          },
        },
      });
    }, 2500);

    //Footer Section---End
  }
}

// ************************************************************************************************************
// ************************************************************************************************************

//                                            GSAP Animation-----END

// ************************************************************************************************************
// ************************************************************************************************************

// ************************************************************************************************************
// ************************************************************************************************************

//                                            Intersection Observer-----Start

// ************************************************************************************************************
// ************************************************************************************************************

// animate Observer------------Start

(function () {
  if (window.innerWidth > 767) {
    let anim = [...document.querySelectorAll(".animate")];

    let options = {
      rootMargin: "0px",
      threshold: 0.2,
    };

    let animate = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.style.animation = `anim 1s ${entry.target.dataset.delay} forwards ease-out`;
        }
      });
    };

    let observer = new IntersectionObserver(animate, options);

    anim.forEach((item, idx) => {
      observer.observe(item);
    });
  }
})();

// Lazy Load Img observer
(function () {
  document.addEventListener("DOMContentLoaded", () => {
    const imgElements = document.querySelectorAll("img[data-src]");

    const lazyLoadingImage = (entries, observer2) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) return;
        entry.target.src = entry.target.dataset.src;
        entry.target.addEventListener("load", () => {
          entry.target.classList.remove("lazy-img");
          observer2.unobserve(entry.target);
        });
      });
    };
    const lazyLoadingObserver = new IntersectionObserver(lazyLoadingImage, {
      threshold: 0,
      rootMargin: "0px 0px 150px 0px",
    });
    imgElements.forEach((img) => lazyLoadingObserver.observe(img));
  });
})();

// clip path observer
(function () {
  if (window.innerWidth > 767) {
    let imageObserver = [...document.querySelectorAll(".img__observer")];
    let option = {
      rootMargin: "-200px",
      threshold: 0.2,
    };

    let setItemActive = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("active");
        }
      });
    };

    let observer2 = new IntersectionObserver(setItemActive, option);

    imageObserver.forEach((item1, idx) => {
      observer2.observe(item1);
    });
  }
})();

// ************************************************************************************************************
// ************************************************************************************************************

//                                            Intersection Observer-----END

// ************************************************************************************************************
// ************************************************************************************************************
